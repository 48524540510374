import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "answer";

export default {
  delete(id) {
    return api().delete(`${moduleName}/${id}/delete`, {
      headers: authHeader()
    });
  }
};
