import axios from "axios";
import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "s3";

export default {
  getCredentials(params) {
    return api().get(
      `${moduleName}/credentials?${querystring.stringify(params)}`,
      {
        headers: authHeader()
      }
    );
  },
  upload(data) {
    return axios.post(
      "https://konstanta-dev.s3.ap-southeast-1.amazonaws.com",
      data
    );
  }
};
